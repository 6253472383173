import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
const AccommodationPage = ({ data }) => (
  <Layout>
    <Helmet>
            <title>Balinese Style Accommodation Byron Bay</title>
            <meta name="description" content="Retreat in to a tropica paradise at The Garden Burees Balinese Style Accommodation in Byron Bay" />
        </Helmet>
    <div>
      <Container className="narrow">
        <Row>
        
          <Col>
          <h1 style={{fontSize:"1.8rem", margin: "1.5rem 0"}}>Stunning Balinese Style Accommodation in Byron Bay</h1>
            {data.allDatoCmsAccomodationPage.edges.map(({ node }, index) => (
              <div key={index}>
                <Img fluid={node.headerImage.fluid} />
                <h3 className="mt-3"> {node.mainHeading}</h3>
                <p>{node.introText}</p>
                <p>
                  <Link to={`/byron-bay-accommodation/${node.slug}`}>
                    <Button outline color="primary">
                      Learn more
                    </Button>
                  </Link>
                </p>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default AccommodationPage

export const query = graphql`
  query AccommodationPageQuery {
    allDatoCmsAccomodationPage {
      edges {
        node {
          mainHeading
          slug
          introText
          headerImage {
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
